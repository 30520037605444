.body-modalcv{
    padding-bottom: 0;
}

.file_row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}

.hori-line{
    color: #d3d3d3;
    margin: 5px 0;
}

.file_roww{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.file_roww span {
    margin: 0;
    padding: 0;
  }


.view-link{
    text-decoration: underline;
    font-size: 12px
}

.remove_action{
    color: rgb(243, 70, 70) ;
    text-decoration: underline;
    font-size: 12px
}

.remove_action{
    cursor: pointer;
}

.modal-footer{
    padding: 0
}

.done-btn-cv{
    margin-right: 0;
    margin-top: 12px ;
    margin-bottom: 12px ;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #f5ab35;
    font-weight: bold;
}
button.nav-link{
    color:#2d2d2d;
}

button.nav-link:hover{
    color: #f5ab35;
}

.filename-box {
    margin-bottom: 15px;
}

.file-count{
    font-style: italic;
    font-size: 16px;
}

.cv-dropdown {
    margin-bottom: 15px;
}