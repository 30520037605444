.jd-list-container {
  /* max-width: 1300px;
  max-height: 1500px; */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  table-layout: fixed;
}

.jd-list-header {
  display: flex;
  text-align: inherit;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625em;
  padding: 1.875em 3.75em;
  background-color: #333333;
}

.jd-list-title {
  font-size: 3.125em;
  display: inline;
  text-align: left;
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin: 0.625em;
  white-space: nowrap;
}

.jd-list-table {
  background-color: #ffffff;
  border-radius: 5px;
}

.error{
  text-align: center;
  align-items: center;
  justify-content:center;
  color:#333333;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  left:50%;
  transform: translate(-50%,-50%);
}

/* styles for screens smaller than 960px wide */
@media screen and (max-width: 60em) {
  .jd-list-container {
    max-width: 100%;
    padding: 0.625em;
  }
}