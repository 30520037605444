.MuiTableBody-root {
    size: 100vh;
    width: 1000px;
}

.MuiPaper-root {
    margin-right: 15px;
}

.custom-table .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
}


.MuiTable-root {
    border-collapse: collapse;
    border: 1px solid #ddd;
    font-size: 14px;
    width: 100%;
}

.MuiTableHead-root {
    background-color: #f2f2f2;
}


.MuiTableCell-root {
    border: 1px solid #ddd;
    text-align: center;

}
.ellipsis-cell {
    height: 50px; /* Adjust the height as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .ellipsis-content {
    padding: 5px; /* Adjust the padding as needed */
  }

.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}

.MuiTableCell-head {
    background-color: #fccf8f !important;
    color: black !important;
    font-weight: bold;
}

.css-levciy-MuiTablePagination-displayedRows {
    display: none;
}

.MuiTableCell-root>div {
    justify-content: space-around;
}

.MuiTableCell-root>ul {
    margin-bottom: 0px;
}

.table-container {
    height: calc(100vh - 350px);
    /* Replace 150px with the height of any other fixed elements on your page */
    overflow-y: auto;
}

.render-data{
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.5;
    justify-content: center;
    height: calc(1.5 * 14px * 3);
  }
  
.inner-render-data{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  