.body-modal{
    padding-bottom: 0;
}

.file_row1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 5px;

}

.file_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}
.file_row span {
    margin: 0;
    padding: 0;
  }


.view-link{
    text-decoration: underline;
    font-size: 12px
}

.remove_action{
    color: rgb(243, 70, 70) ;
    text-decoration: underline;
    font-size: 12px
}

.modal-footer{
    padding: 0
}

.done-btn{
    margin-top: 12px ;
    margin-bottom: 12px ;
    margin-right: 16px ;
}

button.nav-link{
    color: #FA9C1B;
}

button.nav-link:hover{
    color: #2d2d2d;
}

.filename-box {
    margin-bottom: 15px;
}

.file-count{
    font-style: italic;
}

.cv-dropdown {
    margin-bottom: 15px;
}