/* Login Page */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

/* Login Image Container */
.login-image-container {
  flex-basis: 50%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.login-image-container img {
  max-width: 100%;
  max-height: 100%;
}

/* Login Form Container */
.login-form-container {
  flex-basis: 50%;
  padding: 5px;
}

/* Login Card */
.login-card {
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0);
    padding: 50px 20px;
    width: 60%;
    height: 100%;
}

/* Login Header */
.login-header {
  
}

.login-header h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

/* Form */
form {
  display: flex;
  flex-direction: column;
}

/* Form Label */
.label {
  margin-bottom: 5px;
  font-weight: bold;
}

/* Form Input */
.input {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.input[type="text"],
.input[type="password"] {
  width: 100%;
  font-size: 14px;
  color: #333;
}


.login-btn{
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
}

.forgot-text{
  color: #fa9d1bd4;
  font-weight: 400;
}

.forgot-text:hover{
cursor: pointer;
}

/* Text */
.text1 {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}