.MuiTablePagination-displayedRows {
    margin-bottom: 0;
}


.MTableHeader-header-83 .MuiTableCell-paddingCheckbox {
    padding: 8px;
}

.render-data-cv{
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1.5;
    justify-content: center;
    height: calc(1.5 * 14px * 2);
  }
  
.inner-render-data-cv{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  

.text1 {
    font-size: 12px;
}

.orange {
    color: orange;
}

/* styles for screens smaller than 600px wide */
@media screen and (max-width: 600px) {

    .text1 {
        font-size: 12px;
    }

    .orange {
        color: orange;
    }
}

/* styles for screens between 600px and 960px wide */
@media screen and (min-width: 600px) and (max-width: 960px) {
    .text1 {
        font-size: 18px;
    }

    .orange {
        color: orange;
    }
}

/* styles for screens larger than 960px wide */
@media screen and (min-width: 960px) {
    .text1 {
        font-size: 24px;
    }

    .orange {
        color: orange;
    }
}