.main-container {
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  table-layout: fixed;
}

.description-container {
  height: 80vh;
  width: 450px;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  padding: 10px 0px 10px 10px;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  background-color: #fcfafa;

}

.pdf-container {
  height: 80vh;
}

.jd-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;
}

.jd-row:hover{
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  cursor: pointer;
  color: #202020;
  
}

.value{
  font-size: 14px;
}

.job{
  color: #2d2d2d;
}

.total-exp{
  padding:3px 5px;
  background-color: #d3d3d369;
  border-radius: 10px;
}

.heading1 {
  display: flex;
  align-items: center;
  margin: 5px 0;
  position: sticky;
  top: 0;
  background-color: #fcfafa;
  z-index: 1;
  margin-right: 10px;
  min-height: 40px;
  font-size: 20px;
}

.details {
  flex-grow: 1;  /* adjust this value as per your needs */
  overflow-y: scroll;
  padding-bottom: 10px;
}

.key {
  font-weight: bold;
  color: #202020
}

.line {
  color: #626262;
}

.line2 {
  color: #d3d3d3;
}

.button1{
  size: 400px;
  background-color: #4a4b53;
  color: white;
  padding: 14px 20px;
  margin: 8px 10px;
  border: none;
  cursor: pointer;
  width: 20%;

}

.position{
  white-space: nowrap;
  min-width: fit-content; 
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.middle-line {
  flex-grow: 1;
  border: none;
  border-top: 1px solid black;
  margin: 0 10px;
}

.perc{
  font-weight: bold;
  color: #4a4b53;
  margin-left: 10px;

}