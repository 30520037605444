.arrow-indicator {
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
  }
  
  .arrow-up-green {
    border-width: 0 12px 20px 12px;
    border-color: transparent transparent green transparent;
  }
  
  .arrow-down-red {
    border-width: 20px 12px 0 12px;
    border-color: red transparent transparent transparent;
  }