.done-btn-footer{
  margin-right: 16px;
  margin-top: 12px ;
  margin-bottom: 12px ;
}

.css-wb47ya-MuiFormControl-root-MuiTextField-root:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

  .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 0 !important;
}

.css-1kp1dnc-MuiButtonBase-root-MuiChip-root{
  background-color: rgb(255 179 71 / 68%) !important;
}
.modal-dialog {
  max-height: 80vh;
}

.modal-title {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.MuiAutocomplete-popper {
  overflow: hidden !important;
}

.custom-close-button button {
  color: #fff;
}

.modal-body {
  overflow-y: auto;
  max-height: 75vh;
}

.form-label {
  font-weight: bold;
  color: #2d2d2d
}

/* Style for the modal header */
.modal-header {
  background-color: #333333;
  color: rgb(49, 152, 236);
}

/* Style for the modal title */
.modal-title {
  font-weight: bold;
}

/* Style for the form labels */
.text3 {
  font-weight: bold;
  color: #343a40;
}

/* Style for the form select options */
.option {
  color: #343a40;
}

/* Style for the checkboxes in the autocomplete */
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #007bff;
}

/* Style for the form submit button */


/* Style for the form submit button when disabled */
.btn-primary:disabled {
  background-color: #007bff;
  border-color: #007bff;
  opacity: 0.5;
}



/* Style for the spinner next to the form submit button */

/* Style for the autocomplete input */


/* Style for the autocomplete listbox */
.MuiAutocomplete-listbox {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

/* Style for the autocomplete options */
.MuiAutocomplete-option {
  background-color: #fff;
  color: #343a40;
  font-weight: normal;
}

/* Style for the autocomplete options when selected */
.MuiAutocomplete-option[data-focus="true"] {
  background-color: #333333;
  color: #fff;
}
