/* Default styles */
/* .dashboard-container { 
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  height:100vh
  
}

.cont{
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.row-1{
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}

.row-2 {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  justify-content: center;
  height: auto;
  margin: 0 auto;
}
.histogram-box {
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  width: calc(60% - 40px);
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  border-radius: 5px;
  margin: 10px;
}

/* Pie chart box */
/* .pie-chart-box {
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  padding: 20px;
  width: 40%;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  border-radius: 5px;
  margin: 20px auto; /* center the box horizontally */
/* } */

/* 
.box-jd {
  background-color: #f7e1d3;
  color: black;
  text-align: left;
  padding: 20px;
  width: calc(33.33% - 60px);
  height: 170px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  margin: 10px;
  border-radius: 5px;
}

.box-cv {
  background-color: #e5f9cd;
  color: black;
  text-align: left;
  padding: 20px;
  width: calc(33.33% - 60px);
  height: 170px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  margin: 10px;
  border-radius: 5px;
}

.box-count {
  background-color: #dfecf8;
  color: black;
  text-align: center;
  padding: 30px;
  width: calc(33.33% - 60px);
  text-align: left;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  height: 170px;
  margin: 10px;
  border-radius: 5px;
}

.text1 {
  color: #333333;
  font-weight: bold;
  font-size: 20px;
}

.text2 {
  color: black;
  font-weight: bold;
  font-size: 30px;
}

.text3 {
  color:rgb(68, 67, 67);
  font-size: 14px;
  font-weight: 300px;
  background-color: #ececec70;
  border-radius: 5px;
  padding: 3px;
  font-style: italic;
}

.positive {
  background-color: green;
  align-items: center;
  font-size: 24px;
  display: inline;
}

.box{
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
}

.negative {
  background-color: red;
  align-items: center;
  font-size: 32px;
  display: inline;
}  */ 

/* Media queries
@media only screen and (min-width: 768px) {
  .dashboard-container
{
width: 90%;
}

.histogram-box {
width: calc(50% - 30px);
margin-left: 30px;
}

.pie-chart-box {
width: calc(50% - 30px);
margin-right: 30px;
}

.box-jd {
width: calc(33.33% - 40px);
margin: 20px;
}

.box-cv {
width: calc(33.33% - 40px);
margin: 20px;
}

.box-count {
width: calc(33.33% - 40px);
margin: 20px;
}
} */

/* @media only screen and (min-width: 1024px) {
.dashboard-container {
width: 80%;
}

.histogram-box {
width: calc(40% - 50px);
margin-left: 50px;
}

.pie-chart-box {
width: calc(30% - 40px);
margin-right: 40px;
}

.box-jd {
width: calc(33.33% - 40px);
margin: 20px;
}

.box-cv {
width: calc(33.33% - 40px);
margin: 20px;
}

.box-count {
width: calc(33.33% - 40px);
margin: 20px;
}
} */

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: inherit;
  height: calc(100vh - 40px); /* Subtract 40px for padding */
  padding: 20px;
  box-sizing: border-box;
  margin-right: 20px;
}

.text1 {
  color: #333333;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.text2 {
  color: black;
  font-weight: bold;
  font-size: 36px;
}

.text3 {
  color: rgb(101, 101, 101);
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
  padding: 3px;
}

.inc-dec {
  display: flex;
  align-items: center;
}
/* 
.box {
  border: 1px solid #ccc;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
} */

.row-1 {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
}

.row-2 {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-around;
}
/* 
.chart {
  border: 1px solid #ccc;
  height: calc(100% - 100px);
  margin: 20px 20px;
} */

.histogram-box {
  align-items: center;
  background-color: #faf9f96b;
  color: #000000;
  text-align: center;
  padding: 20px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.048);
  border-radius: 5px;
  margin: 20px 20px;
  width: 100%;
  height: fit-content;
  border: 1px solid #ccc;
}

.pie-chart-box {
  border: 1px solid #ccc;
  align-items: center;
  text-align: center;
  background-color: #faf9f96b;
  color: #000000;
  padding: 20px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  border-radius: 5px;
  margin: 20px 20px;
  width: 100%; 
  height: fit-content;
}



.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.title-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-icon {
  height: 2em;
  margin-bottom: 5px;
  margin-left: 4px;
}

.box-jd {
  background-color: #f9ece3;
  align-items: center;
  color: black;
  text-align: left;
  padding: 20px;
  min-height: 160px;
  max-height: 160px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.box-cv {
  background-color: #e5f9cd;
  align-items: center;
  color: black;
  text-align: left;
  padding: 20px;
  min-height: 160px;
  max-height: 160px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

.box-count {
  background-color: #dfecf8;
  align-items: center;
  color: black;
  text-align: left;
  padding: 20px;
  box-shadow: 0 0 10px rgba(141, 141, 141, 0.2);
  min-height: 160px;
  max-height: 160px;
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  }

/* 
@media screen and (min-width: 768px) {
  .chart {
     height: 350px;
  }
  .box{
    height: 160px;
  }
} */
/* 
@media only screen and (max-width: 1024px) {
  
  
  .box-jd, .box-cv, .box-count {
  height: 150px;
  width: 100%;
  }
  
  .histogram-box, .pie-chart-box {
  width: 100%;
  height: 450px;
  margin: 20px;
  }
  } 

  @media only screen and (max-width: 550px) {
    
    .box-jd, .box-cv, .box-count {
    width: 100%;
    height: 150px;
    }
    
    .histogram-box, .pie-chart-box {
    width: 100%;
    height: 0px;
    }
    }  */