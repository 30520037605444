.ResetPassword-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .ResetPassword-image-container {
    flex: 1;
  }
  
  .ResetPassword-form-container {
    flex-basis: 50%;
    padding: 5px;
  }
  
  .ResetPassword-card {
    margin: 0 auto;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0);
    padding: 50px 20px;
    width: 60%;
    height: 100%;
  }
  
  .ResetPassword-header h2{
    text-align: center;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: bold;
    color: #333;
  }
  
  .ResetPassword-form {
    display: flex;
    flex-direction: column;
  }
  
  .label {
    margin-bottom: 5px;
  }
  
  .input {
    padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }
  
.sendOtp-btn {
  margin-left: 5px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}
  
.back-btn{
  margin-right: 5px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}