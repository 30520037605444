@import url('https://fonts.googleapis.com/css?family=Open+Sans');

body{
    margin: 0;
    padding: 0;
    font-size: 14px;
    box-sizing: border-box;
    line-height: 1.5;
    -ms-overflow-style :none;
    overflow: hidden;
}

.mainpage{
    display:flex;
    overflow-y: scroll;
}

select::-ms-expand { display: block; }
